import { getCDNURL } from "authentication/apiDetails";
import { getAPIEndpoint, getMapURL } from "./utils/apiDetails";
// Create a combined enviroment variable. Injected env overrules process env.
export const env = {
  ...process.env,
  ...window._env_,
};

export const BASE_URI = getAPIEndpoint();
export const MAP_URI = getMapURL();
export const CDN_URI = getCDNURL();

window.baseURI = BASE_URI;
window.mapURI = MAP_URI;

export const CONFIG_CONSTANTS = {
  // Fallback pitch used when there isn't one attached to the image. Chosen by Andree.
  FALLBACK_PITCH: -39,
  // Fallback altitude if one isn't supplied & no images exist in the scene.
  FALLBACK_ALTITUDE: 2000,

  // Distance the camera is moved on the forward plane when entering through an image.
  BACKPULL_DISTANCE: -12,
  // Change in degrees of pitch when entering through an image.
  IMAGE_PITCH_CHANGE: -8,
  // Change in degrees of pitch when entering through an image. (Responsible for the side-view)
  IMAGE_YAW_CHANGE: 10,
  // Distance the camera is moved on the forward plane when entering through SCREENSHOT.
  BACKPULL_DISTANCE_SCREENSHOTS: 0.1,
};
