import { authorizedGet, authorizedPost } from "utils/request";
import { Color } from "./rendering/types";
import { SceneData } from "./types";

export const FileFlags = {
  HAS_COLOR: 1 << 0,
  CLEARANCE: 1 << 1,
  INTERNALLY_CLASSIFIED: 1 << 2,
  LOCKED: 1 << 3,
};

export const AccessLevel = {
  OWNER: 1,
  ADMIN: 2,
  SUPERVISOR: 3,
  ANNOTATOR: 4,
  CUSTOMER: 5,
  UPLOADER: 6,
  API: 7,
};

export function getClearanceSystem(scene, signal) {
  return authorizedGet(
    `/potree/pointcloud/clearance_system/${scene}`,
    {},
    {
      signal: signal,
    }
  );
}
export function getSceneData(scene, signal) {
  return authorizedGet(
    `/potree/scene_data/${scene}`,
    {},
    {
      signal: signal,
    }
  );
}
export function getGlobalData() {
  return authorizedGet("/potree/common_data");
}
export function getProjectData() {
  return authorizedGet("/potree/project_data");
}
export function getAnnotations(scene, signal) {
  return authorizedGet(
    `/potree/annotations/${scene}`,
    {},
    {
      signal: signal,
    }
  );
}
export function postAnnotations(
  scene,
  removedVolumes,
  annotationVolumes,
  classifierVolumes
) {
  const data = {
    removed_volumes: removedVolumes,
    annotations: annotationVolumes,
    classifiers: classifierVolumes,
  };

  return authorizedPost(`/potree/annotations/${scene}`, JSON.stringify(data));
}

export interface ClassificationScheme {
  [key: string]: {
    visible: boolean;
    color: [number, number, number, number];
    entries: number[];
  };
}

export function createClassificationScheme(
  sceneData: SceneData
): ClassificationScheme {
  const scheme: ClassificationScheme = {};

  for (const category of Object.values(sceneData.categories.entries)) {
    for (const key of category.entries) {
      const value = sceneData.classes[key];
      const classColor = new Color(value.color);

      scheme[key] = {
        visible: true,
        color: [classColor.r, classColor.g, classColor.b, 1.0],
        entries: value.scene_classes,
      };
    }
  }

  return scheme;
}

interface ColorScheme {
  [key: string]: {
    color: [number, number, number, number];
    visible: boolean;
  };
}
export function createColorSchemeForClearanceAxis(
  displayedAxes: number[],
  sceneData: SceneData
): ColorScheme {
  const colorScheme: ColorScheme = {};

  if (sceneData?.clearance_system) {
    for (const displayedAxis of displayedAxes) {
      const axis = sceneData.clearance_system.axes[displayedAxis];

      // Non-existant axis.
      if (!axis) {
        continue;
      }

      for (const [i, values] of axis.to_labels.entries()) {
        const color = new Color(axis.colors[i]);
        const colorAsArray: [number, number, number, number] = [
          color.r,
          color.g,
          color.b,
          1.0,
        ];

        for (const value of values) {
          colorScheme[value] = {
            color: colorAsArray,
            visible: true,
          };
        }
      }
    }
  }

  return colorScheme;
}
